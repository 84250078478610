
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Validate } from '@/mixin/validator';
import MultipleImageUpload from '@/component/multiple-image-upload.vue';
import SingleImageUpload from '@/component/single-image-upload.vue';
import { request as axios } from '@/util/request';
//import  { Moment }  from 'moment';
import moment from 'moment';
interface Form {
  knight_wx: string;//微信号
  knight_phone: string;//电话
  sharecard_thumbnap: string;//二维码
  kinght_start_time: any;//开始时间
  kinght_end_time: any;//结束时间
  //kinght_time: Moment[];//时间
}
function getFormData() {
  return {
    knight_wx: '',
    knight_phone: '',
    sharecard_thumbnap: '',
    kinght_start_time: '9:00',
    kinght_end_time: '12:00'
    //kinght_time: [],
  };
}

@Component({
  name: 'Customerservice',
  components: {
    MultipleImageUpload,
    SingleImageUpload,
  },
})
export default class Customerservice extends Vue {
  is_loading: boolean = false;
  form: Form = getFormData();
  //knight_wx: any='' //微信号
  //knight_phone: any=''; //电话
  //kinght_time: Moment;//时间
  sharecard_thumbnap: any = '' //二维码图片
  preview_image: string = ''; // 预览图片的路径
  preview_visible: boolean = false; // 预览图片的模态框显示状态
  knight_wx_err: boolean = false
  knight_phone_err: boolean = false
  sharecard_thumbnap_err: boolean = false
  kinght_time_err: boolean = false
  max_title = 100
  max_description = 200
  // 显示模态窗，改变模态窗中的图片路径
  handlePreview(imageUrl: string) {
    this.preview_image = imageUrl;
    this.preview_visible = true;
  }
  // 关闭模态窗
  handlePreviewCancel() {
    this.preview_visible = false;
  }
  //检查必填项
  inputChange(type?: any) {
    console.log(this.form)
    if (type == 'knight_wx') {
      if (this.form.knight_wx == '') {
        this.knight_wx_err = true
        return false
      } else {
        this.knight_wx_err = false
        return true
      }
    } else if (type == 'sharecard_thumbnap') {
      if (this.form.sharecard_thumbnap == '') {
        this.sharecard_thumbnap_err = true
        return false
      } else {
        this.sharecard_thumbnap_err = false
        return true
      }
    } else if (type == 'kinght_time') {
      if (this.form.knight_phone.length <= 0) {
        this.kinght_time_err = true
        return false
      } else {
        this.kinght_time_err = false
        return true
      }
    } else if (type == 'knight_phone') {
      if (this.form.knight_phone == '') {
        this.knight_phone_err = true
        return false
      } else {
        this.knight_phone_err = false
        return true
      }
    }
  }
  //获取数据
  async getData() {
    const res = await axios.get('/boss/knight/customer')
    if (res.status == 200) {
      if (res.data != null) {
        this.form.knight_wx = res.data.wechat_id
        this.form.knight_phone = res.data.mobile
        this.form.sharecard_thumbnap = res.data.qrcode_url
        this.form.kinght_start_time = res.data.service_start_time
        this.form.kinght_end_time = res.data.service_end_time
        this.form.kinght_start_time = moment(this.form.kinght_start_time, 'HH:mm')
        this.form.kinght_end_time = moment(this.form.kinght_end_time, 'HH:mm')
      }

    }
  }
  //保存
  async save() {
    //检查必填项
    console.log(this.form)
    if (this.form.sharecard_thumbnap == '' && this.form.knight_wx == '' && this.form.knight_phone == '') {
      this.$message.error('请至少填写微信二维码、微信号、联系方式中的一项。')
      return false
    } else if (this.form.kinght_start_time > this.form.kinght_end_time) {
      this.$message.error('开始时间不能大于结束时间')
      return false
    }
    else {
      //保存接口
      const body = {
        qrcode_url: this.form.sharecard_thumbnap,
        wechat_id: this.form.knight_wx,
        mobile: this.form.knight_phone,
        service_start_time: this.form.kinght_start_time.format('HH:mm'),
        service_end_time: this.form.kinght_end_time.format('HH:mm')
      }
      const res = await axios.post('/boss/knight/customer', body)
      if (res.status == 200) {
        this.$message.success('保存成功')
        this.getData()
      } else {
        this.$message.error('保存失败')
      }
    }

  }
  async created() {
    await this.getData();

    //this.$route;
  }

}
